// Simule le défilement infini sur mobile pour les rayons et la recherche.
// En jQuery pour éviter les bugs JS sur mobile avec la simulation du clic.
// Cette fonction est conditionnée selon la valeur de #is_loading dans le DOM afin d'éviter un nombre trop conséquent de clics pendant la requête. Voir => generateNewBlocProd.

// La fonction isInViewport sert à contrôler si le bouton apparaît à l'écran. Si c'est le cas, le clic est simulé, sinon non. Cette fonction est appelée dans la condition du clic lors du défilement.

function isInViewport(element) {
    if (element) {
        var elementTop = $(element).offset().top;
        var elementBottom = elementTop + $(element).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    }
}

function triggerClickPagination() {
    var isclicked = false;
    var mainContainer = document.querySelector("body.category");
    var mainContainerSearch = document.querySelector("body.search");

    if (mainContainer || mainContainerSearch) {
        var timer;
        $(window).scroll(function() {
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                var isLoading = $('#is_loading').val();
                const maxItems = document.getElementById('totalElems').value;
                const productLoaded = $('.reloadProduct').length;
                const pagerNav = document.querySelector('.pagerNav.end_pagi');
                const button = document.querySelector('.see_all_product');

                if (isInViewport(button) && isLoading == 0) {
                    $('.see_all_product').trigger('click');
                    $('#is_loading').val('1');
                }
            }, 300);
        });
    }
}

$(function() {
    triggerClickPagination();
});