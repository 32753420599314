/* JS MOBILE */
// Simulate global-e environment. COMMENT THESE FIVE CLASSES WHEN NOT TESTING IN LOCAL
/*class GSetting {
    constructor () {
        this.IsOperatedByGlobalE = true;
    }
}
class GLocalize {
    constructor () {
        this.UserCountry = 'IE';
    }
}

class GInstance {
    constructor () {
        this.UserLocalizeSettings = new GSetting();
        this.LocalizeConfigurations = new GLocalize();
    }
}

class Gconf {
    constructor () {
        this.Instance = new GInstance();
    }
}

class GEM_Services {
    static ConfigurationsService = new Gconf();
}*/
/* functions */

/**
 * Scroll to top
 */
function scrollToTheTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

function openMultiShad(id) {
    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {
        $("#" + id).slideDown(600);
        if($("#" + id).hasClass('closed')) {
            $("#" + id).removeClass('closed');
        }
    });

    if (id !== 'zoomBox') {
        $("#shade").addClass("forMenu");
        $("#shade").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
            closeMultiShad(id);
        });

        if ($("#popup-fairlymade").length) {
            const fairlymade = $("#popup-fairlymade").css('display');

            if (fairlymade === 'block') {
                $("#shade.forMenu").addClass('fairlymade');
            }
        }
    }

    // for product with characteristic fairly made
    if (id == 'popup-fairlymade') {
        $(".accordion_container").addClass("active");
        $('html, body').addClass('noscroll');
    }

    // KLARNA FP lightbox scrollbar
    if (id == 'popup_klarna_3cb') {
        if ($('#popup_klarna_3cb .popup_container').length) {
            $('#popup_klarna_3cb .popup_container').overlayScrollbars({});
        }
    }
}

function moveImg() {
    var bandeau = $('.wrapper_mon_compte .bandeau_img');
    var logBloc = $('.newlogin_page');

    bandeau.prependTo(logBloc);
}

if (document.body.classList.contains('body_login')) {
    moveImg();
}

function searchToggle() {

    var top_search = $('#top_search'),
        main_menu_btn = $('#main_menu_btn'),
        search_home = $('#search_home'),
        shade = $('#shade');

    if (top_search.hasClass('opened')) {
        if (main_menu_btn.hasClass('menu_open')) {

            main_menu_btn.click();
            search_home.focus();
        } else {

            top_search.removeClass('opened');
            shade.removeClass('visible');
            shade.css('z-index', '32')
        }
    } else {


        if (main_menu_btn.hasClass('menu_open')) {

            main_menu_btn.click();
            top_search.addClass('opened');
            search_home.focus();
            shade.addClass('visible');

            shade.on('click', function () {

                if (!search_home.val()) {

                    searchToggle();
                }
            });
        } else {
            top_search.addClass('opened');
            search_home.focus();
            shade.addClass('visible');
            shade.css('z-index', '31');

            shade.on('click', function () {

                if (!search_home.val()) {

                    searchToggle();
                }
            });
        }
    }
}

function clearSearch() {

    var search_home = $('#search_home');

    if (!search_home.val()) {

        searchToggle();
    } else {

        search_home.val('');
        search_home.focus();
    }
}

function positionReassu() {
    // If theres not enough items to display reassu, hide it
    if ($('#scroll_items .list_item .item_container').length > 8) {
        var reassurance = $("#scroll_items .prod_reassurance_swiper");
        var nb_items;

        if (!$('.list_item .item_container.full').length) {
            nb_items = 4;
        } else {
            nb_items = 8;
        }

        reassurance.insertAfter($('.list_item .item_container').eq(nb_items));
        reassurance.show();
        var reassuranceSwiper = new Swiper('.prod_reassurance_swiper', {
            slidesPerView: 'auto',
            loop: true,
            speed: 4000,
            freeMode: true,
            autoplay: {
              delay: 1,
              disableOnInteraction: false,
            },
        });
    } else {
        $("#scroll_items .prod_reassurance_swiper").hide();
    }
}

function resizeAfterHideBanner() {
    if (getCookie("cookie_header_delivery") == "accepted") {
        $("body").removeClass("hasBando");
    }
}

$( document ).ready(function() {
    resizeAfterHideBanner();
    setTimeout(function () {
        /*DropDown - Menu*/
        $('.dropdown.user.connected').on('click touch tap', function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).children('.dropdown.mon-compte').removeClass('active').slideUp(300);
            }
            else {
                $('.dropdown.user.connected').removeClass('active');
                $('.dropdown.mon-compte').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).children('.dropdown.mon-compte').addClass('active').slideDown(300);
            }
        });

        $('.dropdown.contact').on('click touch tap', function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).children('.dropdown.menu_contact').removeClass('active').slideUp(300);
            } else {
                $('.dropdown.contact').removeClass('active');
                $('.dropdown.menu_contact').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).children('.dropdown.menu_contact').addClass('active').slideDown(300);
            }
        });

        $('.dropdown.lang_switch_mob').on('click touch tap', function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).children('.dropdown.menu_lang_switch_mob').removeClass('active').slideUp(300);
            } else {
                $('.dropdown.lang_switch_mob').removeClass('active');
                $('.dropdown.menu_lang_switch_mob').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).children('.dropdown.menu_lang_switch_mob').addClass('active').slideDown(300);
            }
        });
    }, 1000)
});

$(function() {
    // Footer accordion
    $('.footer_accordion_ctn .title').each(function () {
        $(this).click(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parent().find('.footer_navs').removeClass('active').slideUp(300);
            }
            else {
                $('.footer_accordion_ctn .title').removeClass('active');
                $('.footer_accordion_ctn .footer_navs').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).parent().find('.footer_navs').addClass('active').slideDown(300);
            }
        });
    });

    $('.footer_accordion_ctn .col_title').each(function () {
        $(this).click(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parent().find('a').removeClass('active').slideUp(300);
            }
            else {
                $('.footer_accordion_ctn .col_title').removeClass('active');
                $('.footer_accordion_ctn a').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).parent().find('a').addClass('active').slideDown(300);
            }
        });
    });

    // Product
    if ($('#cart_slider_wrapper').length) {
        var cartAssociationSwiper = new Swiper('#cart_slider_wrapper', {
            slidesPerView: 2,
            spaceBetween: 25,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
        });
    }

    // Product
    if ($('#swiper_suggestion .swiper-slide').length > 2) {
        $('#swiper_suggestion').removeClass('no_swiper');
        var cartAssociationSwiper = new Swiper('#swiper_suggestion', {
            slidesPerView: 2,
            spaceBetween: 25,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
        });
    } else {
        $('.recent_btn').hide();
    }

    /** FP ASSOCIATIONS TOGGLE */
    // If container exists
    if ($('.associations_section').length) {
        // First check if there's an active class on each titles and wrappers
        if (!$('.association_title.active').length) {
            $('.association_title').first().addClass('active');
        }
        if (!$('.product_associations_wrapper.active').length) {
            $('.product_associations_wrapper').first().addClass('active');

            // Init also swiper on page load
            if ($('.product_associations_wrapper.active .swiper-container .swiper-slide').length > 2) {
                new Swiper('.product_associations_wrapper.active .swiper-container', {
                    slidesPerView: 2.2,
                    spaceBetween: 10,
                })
            }
        }

        // Once first title and first swiper is active, handle click on each tabs dynamically
        $('.associations_titles .association_title').on('click', function(){

            var title_tab = $(this).attr('data-tab');
            var active_tab = $('.product_associations_wrapper.active');

            // If the clicked title is not active yet, set it to active and remove active class from the last title
            if (!$(this).hasClass('active')) {
                $('.associations_titles .association_title.active').removeClass('active');
                $(this).addClass('active');
            }

            // If new active title, display matching associations tab
            if (title_tab != active_tab.attr('data-tab')) {
                active_tab.removeClass('active');
                $('.product_associations_wrapper[data-tab="' + title_tab + '"]').addClass('active');

                // Swiper activation
                if ($(".swiper-container", '.product_associations_wrapper[data-tab="' + title_tab + '"]')) {
                    // If more than 2 slides, we activate the swiper
                    if ($('.product_associations_wrapper[data-tab="' + title_tab + '"] .swiper-slide').length > 2) {

                        setTimeout(new Swiper('.product_associations_wrapper[data-tab="' + title_tab + '"] .swiper-container', {
                            slidesPerView: 2.2,
                            spaceBetween: 10,
                        }), 500);
                    }
                }
            }
        });
    }

    // Reassurance swiper
    if ($('.prod_reassurance_swiper').length) {
        var reassuranceSwiper = new Swiper('.prod_reassurance_swiper', {
            slidesPerView: 'auto',
            loop: true,
            speed: 4000,
            freeMode: true,
            autoplay: {
              delay: 1,
              disableOnInteraction: false,
            },
        });
    }

    // Rayon position reassurance
    // Rayon add full to pushs
    if ($('.list_item .prod_reassurance_swiper').length) {
        setTimeout(function(e) {
            positionReassu();
        }, 500)

        $('.toggleNumObjects span').on('click', function (e) {
            setTimeout(positionReassu, 500);
        });
    }

    // Homepage
    if ($('.homepage').length) {

        if ($('#super_home_swiper').length) {
            var coverSwiper = new Swiper('#super_home_swiper', {
                slidesPerView: 1,
                loop: true,
                autoplay: false,
                pagination: '.swiper-pagination',
            });
        }

        if ($('#sliderHomeInsta').length) {
            setTimeout(function () {
                var bandoFooter = new Swiper("#sliderHomeInsta", {
                    slidesPerView: 2,
                    centeredSlides: true,
                    loop: true,
                });
            }, 500);
        }
    }

    if ($('.swiperTg').length) {
        var swiperTg = new Swiper('.swiperTg.swiper-container', {
            slidesPerView: 2,
            spaceBetween: 24,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
        });
    }

    if ($('.holder_scrollable').length) {
        var holder_scrollable = new Swiper('.holder_scrollable.swiper-container', {
            slidesPerView: 2,
            spaceBetween: 24,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
        });
    }

    if ($('.columnSwiper').length) {
        var columnSwiper = new Swiper('.three-images .swiper-container', {
            slidesPerView: 1.33,
            spaceBetween: 20
        });
    }

    if ($('.imageTextSwiper ').length) {
        var columnSwiper = new Swiper('.imageTextSwiper', {
            slidesPerView: 'auto',
            loop: true,
            speed: 6000,
            freeMode: true,
            autoplay: {
              delay: 10,
              disableOnInteraction: false,
              waitForTransition: false,
            },
        });
    }

    if ($('.pushCategSlider').length) {
        var pushCategSlider = new Swiper('.pushCategSlider.swiper-container ', {
            slidesPerView: 4.5,
            spaceBetween: 4,
        });
    }

    // LookBook
    if ($('#swiper_suggestion0').length > 0 && $("#swiper_suggestion0 .swiper-slide").length > 2) {
        setTimeout(function() {
            var associationSwiper = new Swiper('#swiper_suggestion0.swiper-container', {
                slidesPerView: 2,
                spaceBetween: 10,
                nextButton: '.gondole-next',
                prevButton: '.gondole-prev',
            });
        }, 500);
    }

    // LookBook
    if ($('#swiper_suggestion1').length > 0 && $("#swiper_suggestion1 .swiper-slide").length > 2) {
        setTimeout(function() {
            var associationSwiper = new Swiper('#swiper_suggestion1.swiper-container', {
                slidesPerView: 2,
                spaceBetween: 10,
                nextButton: '.gondole-next',
                prevButton: '.gondole-prev',
            });
        }, 500);
    }

    /** LOOKBOOK SUGGESTION SLIDER TOGGLE **/
    $('.holder_look_gondoles .wrapper_suggestion0,.wrapper_suggestion1 ').not('.active').hide();
    $('.holder_look_gondoles .look_gondole_choice span').on('click', function() {

        if ($(this).is('.look_gondole_choice .title0')) {
            $('.holder_look_gondoles .wrapper_suggestion1').hide();
            $('.holder_look_gondoles .wrapper_suggestion0').show('fade');
            $('.holder_look_gondoles .look_gondole_choice .title0 ').addClass('active', '');
            $('.holder_look_gondoles .look_gondole_choice .title1 ').removeClass('active', '');
        } if ($(this).is('.look_gondole_choice .title1')) {
            $('.holder_look_gondoles .wrapper_suggestion0').hide();
            $('.holder_look_gondoles .wrapper_suggestion1').show('fade');
            $('.holder_look_gondoles .look_gondole_choice .title1 ').addClass('active', '');
            $('.holder_look_gondoles .look_gondole_choice .title0 ').removeClass('active', '');
        }
        associationSwiper.update();
    })

    /* Header mentions */
    setTimeout(function () {
        if ($("#sliderBando .swiper-slide").length > 1) {
            var bandoHeader = new Swiper("#sliderBando", {
                paginationClickable: true,
                loop: true,
                direction: "vertical",
                autoplay: 3000,
                speed: 2200,
                autoplayDisableOnInteraction: false,
            });
        }
    }, 100)


    // CMS PAGES
    if ($('.cms_category .trigger_drawer').length) {

        $('.trigger_holder').on('click', function(e) {
            $('.cms_subcategory_menu .drawer').removeClass('open');

            if ($(this).find('.trigger_drawer').hasClass('menu_list')) {

                // toggleMenu('filters', 'right')
                toggleDrawer('cms_menu_list');
                // $('.drawer.cms_menu_list').addClass('open');

            } else if ($(this).find('.trigger_drawer').hasClass('date_list')) {
                toggleDrawer('dates_holder');
                // $('.drawer.dates_holder').addClass('open');
            }
        } );

        $(".dropdown_cms").click(function () {
            $(this).next('ul.menu_cms').slideToggle();
        });
    }

    if ($('#slider_cover_module_swiper_0.swiper-container .swiper-slide').length > 1) {
        var sliderCoverModuleSwiper0 = new Swiper('#slider_cover_module_swiper_0.swiper-container', {
            speed: 1500,
            autoplay: 8000,
            loop: true,
            observer: true,
            observeParents: true,
            nextButton :'.swiper-button-next',
            prevButton : '.swiper-button-prev',
        });
    }

    if ($('#slider_cover_module_swiper_13.swiper-container .swiper-slide').length > 1) {
        var sliderCoverModuleSwiper0 = new Swiper('#slider_cover_module_swiper_13.swiper-container', {
            speed: 1500,
            autoplay: 8000,
            loop: true,
            observer: true,
            observeParents: true,
            nextButton :'.swiper-button-next',
            prevButton : '.swiper-button-prev',
        });
    }

    //Suppression des flèches de navigation quand il n'y a qu'une seule slide
    if ($('.cms_page .slider_cover_module .swiper-container').not('.slider_cover_module_swiper').length) {
        $('.cms_page .slider_cover_module .swiper-container').not('.slider_cover_module_swiper').find('.swiper-button-prev,.swiper-button-next').hide();
    }

    if ($("#articles_list_swiper").length) {
        var articlesListSwiper = new Swiper('#articles_list_swiper', {
            slidesPerView: 2,
            spaceBetween: 15,
            nextButton: '.articles-list-swiper-button-next',
            prevButton: '.articles-list-swiper-button-prev',
        });
    }

    //Swiper cms Product
    if($('#cmsProductListSwiper .swiper-slide').length > 2) {
        var cmsProductListSwiper = new Swiper('#cmsProductListSwiper.swiper-container', {
            slidesPerView: 2.21,
            spaceBetween: 12,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }


    // Recently viewed products in rayon
    if ($('#gondole_derniers_articles_vus').length) {
        if ($('#gondole_derniers_articles_vus .item_container').length > 1) {
            setTimeout(function() {
                var rayonGondoleSwiper = new Swiper('#gondole_derniers_articles_vus #swiper_suggestion', {
                    slidesPerView: 2,
                    nextButton: '.gondole-next',
                    prevButton: '.gondole-prev',
                });
            }, 500);
        }
    }

    // "Les incontournables" - Store detail page
    if ($('.satellite_store_details #productAssociationSwiper').length) {
        if ($('.satellite_store_details #productAssociationSwiper .item_container').length > 2) {
            var rayonGondoleSwiper = new Swiper('#productAssociationSwiper', {
                slidesPerView: 2,
                spaceBetween: 15,
                nextButton: '.gondole-next',
                prevButton: '.gondole-prev',
            });
        }
    }

    // SHIPUP MOBILE
    if ($('body.order .content_commandes .shipup_cta').length) {

        $('.content_commandes .shipup_cta').each( function() {
            // Remove attr from app component because we don't use openMultiShad on mobile
            $(this).removeAttr('onclick');

            $(this).on('click', function() {
                $('#shipup-container').slideToggle(500, function() {
                    // Scroll when slideToggle is done
                    $("html, body").animate({ scrollTop: $("#shipup-container").offset().top - $('#site_head .banner_wrapper').outerHeight() }, 1000);
                });
            });
        });
    }
    //Gestion des Iframes
    // Re-application de styles sur l'iframe une fois que le doc est chargé pour éviter le bug des bordures
    setTimeout(function() {
        if ($('.product_page .product_picture iframe').length) {
            $('.product_page .product_picture iframe').css('width', '101%');
        }
    }, 2000);

    $("body.checkout #paiement_3Times_cofidis").prepend("<div class='squareBloc'>");
    setTimeout(function () {
        $("body.checkout #paiement_3Times_cofidis .squareBloc").on("click", function () {
            $("body.checkout #paiement_3Times_cofidis p.bank_subtitle").click();
        });
    }, 200);

    if ($('.nrc_filter_by_rate .order_rate').length) {
        $('.nrc_filter_by_rate .order_rate').on('click tap touch', function () {

            $('.nrc_filter_by_rate .container_tri').addClass('visible');
            $('.nrc_filter_by_rate .order_rate').addClass('opened');

            $('.order_rate_second, .rating_single').on('click tap touch', function () {
                $('.nrc_filter_by_rate .container_tri').removeClass('visible');
                $('.nrc_filter_by_rate .order_rate').removeClass('opened');

            });
        });
    }

    // Ajout phrase "vous avez déjà un compte" - Page de connexion
    var $deja_compte = Translator.translate('existing_user_pwd_reset_' + site_id);

    $( ".login_form_wrapper .w-newlogin-form" ).append( $('<p class="deja_compte">' + $deja_compte + '</p>') );

    /***** NAV CATEGORY SWIPER *****/
    if ($('#navCategorySwiper').length) {

        var categoryInitialSlide;

        $('#navCategorySwiper .swiper-slide').each(function (i, obj) {

            if ($(obj).hasClass('actif')) {

                categoryInitialSlide = i;
            }
        });

        var navCategorySwiper = new Swiper('#navCategorySwiper', {
            slidesPerView: '2.6',
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            initialSlide: categoryInitialSlide,
        });
    }

    /**
     * Init .item_container slider on page loaded
     */
    if($('body').hasClass('category') || $('body').hasClass('search')) {
        initItemSlider(false);
        /**
        * Load global .item_container slider initialization on window scroll
        */
        window.addEventListener("scroll", function() {
            initItemSlider(false);
        });
    }

    if ($('body.paymentOption').length) {
        // Toggle to add new payment card in account payment methods.
        $(".account_payment_options .addNewCard").click(function () {
            const $btn = $(this);
            $btn.addClass("loading");
    
            $(".wrapper_form").fadeOut(700, () => {
                $("#psp_form").fadeIn(700);
                $btn.removeClass("loading");
            });
        });
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
            $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            $('.misc_cookiesinfo #shadCookie').removeClass('actif');
        }
    });

    if ($('body').hasClass('search')) {
        setTimeout(function () {
            $('.swiper-wrapper .block_vid').css('opacity', '1');
        }, 2500);
    }
});

// // Re-application d'une width sur l'iframe dans le rayon pour parer le bug de la width 100% qui ne prend pas au chargement de page
setTimeout(function() {
    if ($('.product_page .product_picture iframe').length) {
        $('.product_page .product_picture iframe').css('width', '100%');
    }
}, 500);

//Add class on iframe's parent to easily swipe on FP
$('.product_page .product_picture iframe').closest('.swiper-slide').addClass('swipe_iframe');

function reinitFilters(name) {
    var menu = document.querySelector('.drawer.' + name);

    if (menu) {
        var checked_inputs = menu.querySelectorAll('.drawer.' + name + ' [type="checkbox"]:checked');
        var years_inputs = menu.querySelectorAll('.drawer.' + name + ' .dropdown_cms');

        if (checked_inputs) {
            checked_inputs.forEach(function(el) {
                if (el.checked) {
                    el.click();
                }
            });

            if (years_inputs) {
                years_inputs.forEach(function(el) {
                    if ($(el).next('.menu_cms').is(":visible")) {
                        $(el).next('.menu_cms').slideToggle();
                    }
                });
            }

            setTimeout(() => {
                toggleDrawer(name);
            }, 200);
        }
    }
}

function toggleDrawer(name) {

    var menu = document.querySelector('.drawer.' + name);
    var shad = document.getElementById('shade');

    if (menu) {

        if (menu.classList.toggle('open') && shad) {

            shad.classList.add('visible');
            shad.classList.add('forMenu');
            document.body.classList.add("noscroll");
        } else if (shad) {
            shad.classList.remove('visible');
            shad.classList.remove('forMenu');
            document.body.classList.remove("noscroll");
        }
    } else if (shad) {

        shad.classList.toggle('visible');

    }
}

function changeLocale(locale, uri, site) {
    setTimeout(function () {
        $.ajax({
            url: path_relative_root + "ajax_get_uri_by_lang.php",
            type: "post",
            data: {lang: locale, url: uri, site: site},
            success: function (data) {
                if (data != "") {
                    var force = "";
                    if (uri == "/") {
                        force = "?force";
                    }

                    var href_lang = data + force;

                    if (typeof href_change_lang != "undefined") {
                        href_lang = href_change_lang + href_lang;
                    }

                    location.href = href_lang;
                }
            },
        });
    }, 400);
}

function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    var divId = 'popup_numero_retour_'+(multiTransp ? idOrderTransp : idOrder);

    var numDIVObjet = document.getElementById(divId);

    numDIVObjet.style.display = "block";
    numDIVObjet.style.opacity = "1";
    numDIVObjet.style.zIndex = "20";
    document.getElementById("general_shade").style.display = "block";
    document.getElementById("general_shade").style.opacity = ".2";
    document.getElementById("general_shade").style.position = "fixed";
    document.getElementById("general_shade").style.zIndex = "15";
    document.getElementById("general_shade").style.left = "0";


    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);


    $("html, body").animate({ scrollTop: 0 }, (speed * 2), function () { });
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp)
{
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    } else {
        data = 'idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    }

    $.ajax({
        url : path_relative_root + 'ajax_create_etiquette.php',
        type : 'post',
        data : data,
        success : function (res) {
            if ( res.substr(0,5) == 'false' ) {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}


function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var formId = '#return_products_form';
    formId += '_'+(multiTransp?idOrderTransp:idOrder);

    var has_errors = false;

    $(formId+" .return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $(formId).serialize();
        data += '&idOrder=' + (multiTransp?idOrderTransp:idOrder) + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}


// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });

    initDailymotionVideos();
});

function closeMultiShad(id) {
    $("#" + id).removeClass('open');
    $("#availabilityInStore").slideUp(300);
    $("#" + id).fadeOut();
    $('#general_shade').css('display', 'none');
    $('#shade').fadeOut();
    $("#shade").removeClass("forMenu");
    $("#shade").removeClass("fairlymade");

    // for product with characteristic fairly made
    if (id == 'popup-fairlymade') {
        $(".accordion_container").removeClass("active");
        setTimeout(() => {
            $('html, body').removeClass('noscroll');
        }, 600);
    }

    modBoxClose();
}

//Add modbox class on gift lightbox
$('#giftPackageLightbox').addClass('modbox');

$(document).ready(function() {
    //Remove binded function to avoid bad transition effect
    $('#giftPackageLightbox .close_pop.close').prop("onclick", null).off("click");
    $('#giftPackageLightbox .close_pop.close').on('click touch tap', function() {
        closeMultiShad();
    });
});


// /**
//  * Ajout le fonction faqinit()
//  * sur mobile
//  */
function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }

        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function () {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1);
    });
}

function researchFaq(node_id, level, question_id) {

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({ scrollTop: $(".faq_question_" + question_id + "").offset().top - 100 }, 1000);

}

/**
* Swiper mobile "Mon compte" menu
*/
if (($('body').hasClass('customer') || $('body').hasClass('newsletter') || $('body').hasClass('wishlist') || $('body').hasClass('order') || $('body').hasClass('paymentOption')) && $('#menu_account_slider_mob').length > 0) {

    var menuInitialSlide = 0;

    $('.menu_account_slider_mob').css({ 'display': 'block', 'opacity': '0' });

    $('.menu_account_slider_mob .swiper-slide').each(function (i, obj) {
        if ($(obj).hasClass('activeMenu')) {
            menuInitialSlide = i;
        }
    });

    var menuAccSwiperMob = new Swiper('#menu_account_slider_mob', {
        slidesPerView: 2.5,
        centeredSlides: true,
        initialSlide: menuInitialSlide,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        slidesOffsetBefore: 30,
        slidesOffsetAfter: 30,
        onInit: function () {
            $('.menu_account_slider_mob').fadeTo("slow", 1);
        },
    });
}

/***** CART - Move upselling place *****/
$( document ).ready(function() {
    $('.upselling_panier').insertAfter('.order_recap .order_total.subtotal');
});

$('.order .sub_tunnel .cart_item_container .cart_item_summary .cart_item_size label:contains("Size :")').text('Size: ');



var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}



// FP - Set Associated Products Swiper slides_per_view param into a variable to be correctly passed into _app's loadProduct() {} function and be re-init on colorChange (example)
var associatedProduct_spv = 2.2;
var assoSpaceBetween = 10;

// TUNNEL REASSURANCE SWIPER PARAMS
var oReassuSwiper = {
    'element' : '.prod_reassurance_swiper',
    'params' : {
        slidesPerView: 'auto',
        loop: true,
        speed: 4000,
        freeMode: true,
        autoplay: {
            delay: 1,
            disableOnInteraction: false,
        }
    },
};

/**
 * Permet de calculer les mensualités d'un paiement Cofidis 3x
 * @param {string} amount
 */
function calculCofidis3Cb()
{
    var amount = $('#montantCalcul').val();
    if(amount > 0) {
        $.ajax({
            url : 'https://www.simulateurcofidis.com/3cb/index.php',
            type : 'get',
            data : {'montant': amount, 'key': 'RlX3lf1f98Cal6r0v1ul7Phq7YfJSdnybnvuXuL6T9RaVGhRyr', 'defaut': 0, 'retour': 'flux'},
            success : function (response) {
                var resJson = JSON.parse(response);
                var montant = Number.parseFloat(resJson.montant).toFixed(2).toString().replace('.', ',');
                var mensualite = Number.parseFloat(resJson.mensualite).toFixed(2).toString().replace('.', ',');
                var derniere_mensulatie = Number.parseFloat(resJson.derniere_mensulatie).toFixed(2).toString().replace('.', ',');
                var montant_du = Number.parseFloat(resJson.montant_du).toFixed(2).toString().replace('.', ',');
                $('#tab_mensualites .montant .tab_amount span').html(montant);
                $('#tab_mensualites .mensualite_1 .tab_amount span').html(mensualite);
                $('#tab_mensualites .mensualite_2 .tab_amount span').html(derniere_mensulatie);
                $('#tab_mensualites .montant_du .tab_amount span').html(montant_du);
                $('#tab_mensualites .frais .tab_amount span').html(resJson.frais);
            }
        });
    }
}

function cofidisScrollbar() {
    // Wait for _app modBoxOpen to open lightbox
    setTimeout(function() {
        if ($('#popup_cofidis3cb .popup_container').length) {
            $('#popup_cofidis3cb .popup_container').overlayScrollbars({});
        }
    }, 800);
}

function submitToCalcul(e) {
    if (e.keyCode && e.keyCode == "13") {
        calculCofidis3Cb();
    } else {
        return;
    }
}


$(function() {
    $("body.checkout #paiement_3Times_cofidis").prepend("<div class='squareBloc'>");
    setTimeout(function() {
        $("body.checkout #paiement_3Times_cofidis .squareBloc").on("click", function(){
            $("body.checkout #paiement_3Times_cofidis p.bank_subtitle").click();
        });
    }, 200);

    if ($('.nrc_filter_by_rate .order_rate').length) {
        $('.nrc_filter_by_rate .order_rate').on('click tap touch', function() {

        $('.nrc_filter_by_rate .container_tri').addClass('visible');
        $('.nrc_filter_by_rate .order_rate').addClass('opened');

        $('.order_rate_second, .rating_single').on('click tap touch', function() {
        $('.nrc_filter_by_rate .container_tri').removeClass('visible');
        $('.nrc_filter_by_rate .order_rate').removeClass('opened');

        });
    });
    }
});

function triggerSearchDataLayer(product_id) {

    if (product_id !== '' && from_type == 'search' ) {
        var product = window.productlist[product_id];
        if (product !== undefined && product['list'] !== undefined) {
            delete product["list"];
        }
        dataLayer.push({
            'event': 'productClick',
            'ecommerce': {
                'click': {
                    'actionField': {'list': 'Search Results'},
                    'products': [product]
                }
            },
            'eventCallback': 'function() { document.location = productObj.url}'
        });
    }
    return false;
}


/**
 * change filter selected
 * avis verifie
 */
function clickOnFilter(elem){
    var liElem = elem.parentElement;
    var ulElem = liElem.parentElement;
    var containerElem = ulElem.parentElement;
    var buttonFilter = containerElem.querySelector('p.nrc_button_filter');
    var textLiSelected = buttonFilter.querySelector('span.text_li_selected');
    var curentLiSelected = ulElem.querySelector('li.select');
    var containerFilter = document.querySelector("#lightbox_netreviews_comments .nrc_filter");
    curentLiSelected.classList.remove('select');
    liElem.classList.add('select');
    textLiSelected.innerText = elem.innerText;
    containerFilter.classList.remove("hover_on");
}

/**
 * init avis verifie
 */
function openLightboxNetreviews(){
    window.scroll({
        top: 0,
        behavior: 'smooth'
    });
    var lightbox = document.querySelectorAll("#lightbox_netreviews_comments");
    if (lightbox.length > 0){
        var containerFilter = lightbox[0].querySelector(".nrc_filter");
        var transitOn = false;
        var touchstartContainerFilter = false
        containerFilter.addEventListener("touchstart", function(){
            function testTouchInContainer(e) {
                if (!e.path.includes(containerFilter)){
                    containerFilter.classList.remove("hover_on");
                    document.removeEventListener("touchstart", testTouchInContainer);
                    touchstartContainerFilter = false
                }
            }
            document.addEventListener("touchstart", testTouchInContainer)
            containerFilter.querySelector("ul").style.display = "block";
            containerFilter.classList.add("hover_on");
            transitOn = true
            touchstartContainerFilter = true
            if (!transitOn) {
                setTimeout(function(){
                    transitOn = false
                }, 300);
            }
        });
    }
}


$(document).ready( function() {
    // Hide pre-header when empty
    var preheaderWrapper = $('.bandeauMenuAffix');
    var siteGlobalWrapper = $('body.hasBando');

    if (!preheaderWrapper.length) {
        preheaderWrapper.hide();
        siteGlobalWrapper.addClass('preheader-hide');
    }
    else {
        siteGlobalWrapper.removeClass('preheader-hide');
    }

    // ajouter la tri "Nouveautés" devant la tri des prix
    let new_option = $("#wrapper_price_filtre .filter_options .option:last-child");
    let price_increase = $( "#wrapper_price_filtre .filter_options .option:first-child" );
    $(new_option).insertBefore( price_increase );

    // show popin fairlymade
    if(document.getElementById("open-popup-fairlymade") != null) {
        document.getElementById("open-popup-fairlymade").addEventListener("click", function() {
            var popupId = "popup-fairlymade";

            openMultiShad(popupId);
            $("html, body").animate({ scrollTop: "0" });
        });
    }

    //Close popin fairlymade
    if(document.getElementById("button_close_fairlymade") != null) {
        document.getElementById("button_close_fairlymade").addEventListener("click", function() {
            var popupId = "popup-fairlymade";

            closeMultiShad(popupId);
        });
    }

    var mainContainerBoutiques = document.querySelector("body.store_locator");
    if (mainContainerBoutiques) {
        $(window).scroll(function() {
            $('#voir_plus').trigger('click');
        });
    }
} );

//paypal loader
$( function () {
    $( ".wrapper_bloc_banque.paypal" ).on( 'click touch tap', function () {
        $( this ).find( ".bank_subtitle" ).addClass( "loader_paypal" );
    } )
});

function paginationGoToPage(page, fromScrollBottom, fromScrollTop)
{
    page = page || 1;

    var type = document.getElementById('type_tri').value;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;


    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    if (from_type == 'product') {
        generateNewBlocProd(type, idObj, page, null, null, fromScrollBottom, fromScrollTop, true);
    } else {

        generateNewBlocSearch(document.getElementById('nb_prod').value, bloc_page, null, fromScrollBottom, fromScrollTop);
    }
}

// Creates pager in shop's districts
function paginate(page) {

    if (!document.getElementById('filters_form'))
        return;

    var form = document.getElementById('filters_form'),
        nb_total_page = Math.ceil(form.totalElems.value / form.nb_prod.value),
        pagination = $(document.getElementById('pagination_content')),
        page = page || 1;

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: pagination.html.bind(pagination),
        complete: function() {
            var productLoaded = $('.reloadProduct').length;
            var total_element = document.getElementById('totalElems').value;

            if (productLoaded == total_element) {
                $('.see_all_product').removeClass('loading');
            } else {
                $('.see_all_product').addClass('loading');
                $('.see_all').show();
            }
            $('.nbProdDisplayed').html(Translator.translate('nbProdDisplayed', productLoaded, total_element));
            $('.loadedGauge').html('<progress value="' + productLoaded + '" max="' + total_element + '"></progress>');
        }
    });
}

function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination) {

    if ($('.see_all')) {
        $('.see_all').addClass('loading');
    }

    if (use_new_filter_url) {
        output = triggerFilter( page, type, idObj, nb_product, 'filters_form' );
        var nb_product = nb_product || parseFloat( $( '#nb_prod' ).val() );
    } else {
        var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        value = getInputVal.bind(this),
        output = {},
        is_from_page = false,
        nb_product = nb_product || parseFloat($('#nb_prod').val()),
        type = type || $('#type').val(),
        idObj = idObj || parseFloat($('#filters_form #idObj').val()),
        startPage = parseInt($('#page_start').val()),
        key;

        // Get all filters
        form = Array.prototype.slice.call(form.elements, 0);

        if (fromScrollTop || fromScrollBottom) {
            $('#is_loading').val('1');
        }

        form.forEach(function (elm) {
            // Build an object to store active filters
            if ((elm.type === 'checkbox' || elm.type === 'radio') && elm.name != 'tri') {
                if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                    if (elm.name == 'brand') {
                        elm.name = 'promo';
                    }

                    if (output[elm.name] === undefined) {
                        output[elm.name] = [];
                    }

                    output[elm.name].push(elm.value);
                }
            }
        });

        // Convert filters arrays to strings
        for (key in output) {
            if (output.hasOwnProperty(key)) {
                if (output[key].constructor === Array) {
                    output[key] = output[key].join('~');
                }
            }
        }

        if ($(".filter_date").length > 0) {
            output.multi_object = 'true';
            output.id_category_super = id_category_super;

            if (typeof (output['date']) == 'undefined') {
                output.date = 4;
            } else {
                if (output['date'] == 4) {
                    output['date'] = tmp_date;
                }
            }
        } else {
            if (output['date'] == 4) {
                output['date'] = tmp_date;
            }
        }
    }

    if (fromScrollBottom && !fromScrollTop) {
        $("#initial_page").val(page + 1);
    }

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = page * nb_product;
        is_from_page = true;

    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }

    // Add misc values to output object
    output.page = $(".reloadProduct").length;
    output.type = type;
    output.parentPath = path_relative_root;
    output.idObj = idObj;
    output.nb_prod = nb_product;
    output.is_page = is_from_page;
    output.nb_tot_prod = this.totalElems.value;
    output.object_id = this.object_id.value;

    if (typeof id_category_super != 'undefined') {
        output.id_category_super = id_category_super;
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix = $("#amount1").val() + "~" + $("#amount2").val();
    }

    /** LEGACY **/
        // Recovery of sorting by price (top down or bottom up) which passes, unfortunately, by the url
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html$');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // If there is an order in the prices we load the id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        tri = res[1][0];
    }
    /** END LEGACY **/

    if ($("input[name=tri]:checked").val() != undefined) {
        output.tri = $("input[name=tri]:checked").val();
    }

    if (page === 'all') {
        /** LEGACY **/
        hash = location.hash.substr(1);
        if ((/i=[0-9]+/).test(hash)) {
            // Vu que le header est en absolute on le sort du calcul
            var marginTop = $('.banner_wrapper').height() + 10,
                $elem = $('#' + hash.replace('=', '')),
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {
                $(window).scrollTop(top);
            }, 1000);
        }

        /** END LEGACY **/
    } else {
        if (bloc_prod_xhr) {
            bloc_prod_xhr.abort();
        }

        if (fromPagination) {
            output.page = $("#scroll_items .reloadProduct").length;
            output.handle_push = "false";
        } else {
            output.page = 0;
        }
        $('.loader_scroll .see_all_product').addClass("disable_click");

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function (res) {

                var old_elm = document.querySelector('.list_item');
                var content, itm_length;

                if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {
                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();

                itm_length = parseFloat(res[1]);

                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + canonical);

                $("link[rel^='alternate'][hreflang^='x-default']").attr("href", JSON.parse(res[8])[1].en);
                $("link[rel^='alternate'][hreflang^='en-gb']").attr("href", JSON.parse(res[8])[2].en);
                $("link[rel^='alternate'][hreflang^='en-us']").attr("href", JSON.parse(res[8])[3].en);
                $("link[rel^='alternate'][hreflang^='en-ch']").attr("href", JSON.parse(res[8])[4].en);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext,commentNext, 'next');

                if ($('.rayonCategSlider').length && output.object_id) {

                    $('.rayonCategSlider .menuitem').removeClass('actif');
                    $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                    var rayonInitialSlide;

                    $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                        if ($(obj).hasClass('actif')) {
                            rayonInitialSlide = i;
                        }
                    });

                    rayonCategSlider.slideTo(rayonInitialSlide);
                } else {
                    $('.rayonCategSlider .menuitem').removeClass('actif');
                }

                if (content !== '') {
                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (!fromScrollBottom && !fromScrollTop) {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('nbr_items').innerHTML = itm_length;
                    document.getElementById('totalElems').value = itm_length;

                    if (!fromPagination) {
                        $("#scroll_items .list_item").html(content);
                    } else if (fromScrollTop) {
                        var firstMsg = $('#scroll_items .item_container:first');
                        var curOffset = firstMsg.offset().top - $(document).scrollTop();
                        old_elm.insertAdjacentHTML('afterbegin', content);
                        $(document).scrollTop(firstMsg.offset().top - curOffset);
                        //L'inverse pour le bas
                    } else {
                        old_elm.insertAdjacentHTML('beforeend', content);
                    }

                    if (one_product) {
                        $('div.item_container').addClass('full');
                    }

                    if (parseFloat($('#filters_menu').css('right')) === 0) {
                        toggleMenu('filters', 'right');
                    }

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                    if (typeof lazyloadImage === 'function') {
                        lazyloadImage();
                    }
                }
            },
            complete: function () {
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (!fromScrollBottom && !fromScrollTop) {
                    //reset initiialPage if from filter
                    $("#initial_page").trigger("change");
                    setTimeout(function () {
                        $("html, body").animate({
                            scrollTop: ($(window).scrollTop() + 10)
                        }, 200);
                    }, 250);
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');

                    /**
                     * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                     * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                     */

                    if ($('body.category').length) {
                        var origin = $('.item_container:not(.push)').find('img.watched'),
                            target = $('span.ill_img'),
                            targetHeight = origin.height();
                    }
                }

                setTimeout(function () {
                    if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {
                        var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');
                        toggleMenu(menuId);
                    }
                }, 200);

                /**
                 * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                 */
                var productLoaded = $('.reloadProduct').length;
                var total_element = document.getElementById('totalElems').value;

                if (productLoaded == total_element) {
                    $('.see_all_product').removeClass('loading');
                }
                $('.nbProdDisplayed').html(Translator.translate('nbProdDisplayed', productLoaded, total_element));
                $('.loadedGauge').html('<progress value="' + productLoaded + '" max="' + total_element + '"></progress>');

                if (productLoaded >= total_element) {
                    $('.loader_scroll').hide();
                } else {
                    $('.loader_scroll').show();
                }
                $('.loader_scroll .see_all_product').removeClass("disable_click");

                initDailymotionVideos();
            }
        });
    }
}

// Updates products display in shop districts
// May be simplified
function generateNewBlocSearch(nb_product, page, btn, fromScrollBottom, fromScrollTop, type, idObj) {

    if ($('.see_all').length) {
        $('.see_all').addClass('loading');
    }
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        is_from_page = false,
        key;

    form = Array.prototype.slice.call(form.elements, 0);

    form.forEach(function(elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.value !== '') {
                if (output[elm.name] === undefined) {

                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === 'hidden') {
            if (elm.value !== '') {
                output[elm.name] = elm.value;
            }
        }
    });

    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
    }

    for (key in output) {

        if (output.hasOwnProperty(key)) {

            if (output[key].constructor === Array) {

                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = output.page = page * nb_product;
        is_from_page = true;
    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html');

    if (pattern.test(url)) {
        var res = url.match(pattern);
        output.tri = res[1][0];
    }
    // Dtalayer V3 filter_interaction event
    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
        DataLayerTrigger.filerInteractionSearchMobile(output);
    }
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    $.ajax({
        url: path_relative_root + 'ajax_reload_search.php',
        type: 'get',
        data: output,
        success: function(res) {

            var new_elm = document.createElement('div'),
                old_elm = document.querySelector('.list_item'),
                content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            if (content !== '') {

                if (!fromScrollBottom && !fromScrollTop) {
                    new_elm.className = 'list_item';
                    new_elm.innerHTML = content;
                    old_elm = document.querySelector('#scroll_items');
                    $(old_elm).find('list_item').remove();
                }

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (!fromScrollBottom && !fromScrollTop) {
                    backToTop();
                    old_elm.innerHTML = "";
                }
                $('#is_loading').val('0');
                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.appendChild(new_elm);
                }

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //L'inverse pour le bas
                } else if (fromScrollBottom) {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                /** LEGACY **/

                var js_compteur = $('.vf_countdown').length;
                var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                        }
                    } catch (e) {}
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                        eval(js_encart);
                    } catch (e) {}
                }

                /** END LEGACY **/

                if (one_product) {
                    $('div.item_container').addClass('full');
                }

                if (parseFloat($('#filters_menu').css('right')) === 0) {

                    toggleMenu('filters', 'right');
                }

                if (window.lazyload) {

                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }
            }
        },
        complete: function() {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');

            setTimeout(function() {

                var loader = $('.button + .loader');

                if (loader.hasClass('loading')) {

                    loader.removeClass('loading');
                } else {

                    loader.parent().removeClass('loading');
                }
                if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {
                    var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');
                    toggleMenu(menuId);
                }
            }, 200);

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');

                /**
                 * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                 * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                 */

                if ($('body.category').length) {
                    var origin = $('.item_container:not(.push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();

                    target.each(function() {
                        $(this).css({ minHeight: targetHeight });
                    })
                }
            }

            if (!fromScrollBottom && !fromScrollTop) {
                $("#initial_page").val(1);
            } else if (fromScrollBottom && !fromScrollTop) {
                $("#initial_page").val(page + 1);
            }

            initDailymotionVideos();
        }
    });
}

function generateNewBlocFilter(type, idObj, page, nb_product, btn, typeFiltre, id) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
            value = getInputVal.bind(this),
            output = {},
            tmpoutput = {},
            is_from_page = false,
            key;

    var thisform = form;

    // Get all filters
    form = Array.prototype.slice.call(form.elements, 0);
    var keys = new Array();

    output = triggerFilter(page, type, idObj, nb_product, 'filters_form');

    /** LEGACY **/

    //Recuperation du trie par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
    let tri = '';
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('order')) {
        tri = urlParams.get('order');
    }

    /** END LEGACY **/

    if (!tri) {
        output.tri = tri;
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    // For better ajax response, execute these two lines
    output.handle_push = 'false';

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_change_filtre_produit.php',
        type: 'get',
        data: output,
        success: function (response) {

            var resultats = jQuery.parseJSON(response);

            var typeFilter = resultats.filter;
            var array_size = resultats.size;
            var array_colors = resultats.color;
            var array_brand = resultats.brand;
            var array_eclat = resultats.eclat;
            var array_store = resultats.store;

            if (typeFilter == 'sscat' && $('.container_products_lookbook').length == 0 ) {
                if (array_colors.length == 0 || array_size.length == 0) {
                    changeajaxfilterRayon(thisform, 'all', id, true);
                    return true;
                }
            }

            if (typeFilter != 'taille') {
                generateListFilter('taille', array_size, true);
            }

            if (typeFilter != 'couleur') {
                generateListFilter('couleur', array_colors, true);
            }

            if (typeFilter != 'brand') {
                generateListFilter('promo', array_brand, true);
            }

            if (typeFilter != 'eclat') {
                generateListFilter('eclat', array_eclat, true);
            }

            if (typeFilter != 'store') {
                generateListFilter('store', array_store, true);
            }

            /* Nombre de produits */
            // Get all filters
            form = Array.prototype.slice.call(thisform.elements, 0);
            // Build an object to store active filters
            form.forEach(function (elm) {
                if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                    if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                        if (tmpoutput[elm.name] === undefined) {
                            tmpoutput[elm.name] = [];
                        }

                        if (elm.name == 'charact') {
                            tmpoutput[elm.name].push(elm.value);
                        } else {
                            tmpoutput[elm.name].push(parseFloat(elm.value));
                        }
                    }
                }
            });

            // Convert filters arrays to strings
            for (key in keys) {
                if (tmpoutput.hasOwnProperty(key)) {
                    output[key] = tmpoutput[key].join('~');
                } else {
                    delete output[key];
                }
            }

            if( $(".filter_date").length > 0 ) {

                output.multi_object = 'true';
                output.id_category_super = id_category_super;

                if( typeof(output['date']) == 'undefined' ) {
                    output.date = 4;
                }
                else {
                    if (output['date'] == 4)
                        output['date'] = tmp_date;
                }
            }
            else {
                if (output['date'] == 4) {
                    output['date'] = tmp_date;
                }
            }

            bloc_prod_xhr = $.ajax({
                url: path_relative_root + 'ajax_reload_products.php',
                type: 'get',
                data: output,
                success: function (res) {
                    var old_elm = document.getElementById('scroll_items');

                    res = res.split('><><');

                    var content = res[0].trim();

                    if (res[1] !== '') {
                        var itm_length = parseFloat(res[1]);
                        $("#scroll_items .list_item").html(content);
                        //Permet d'actualiser lors de l'application d'un filtre, le nombre de produits total restants après application
                        //Info acutalisée en haut du filters_menu - potentiellement en display:none;
                        document.getElementById('nbr_items').innerHTML = itm_length;
                        document.getElementById('totalElems').value = itm_length;

                        /**
                         * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                         */
                        var productLoaded = $('.reloadProduct').length;
                        var total_element = itm_length;

                        if (productLoaded == total_element) {
                            $('.see_all_product').removeClass('loading');
                        }
                        $('.nbProdDisplayed').html(Translator.translate('nbProdDisplayed', productLoaded, total_element));
                        $('.loadedGauge').html('<progress value="' + productLoaded + '" max="' + total_element + '"></progress>');

                        if (productLoaded >= total_element) {
                            $('.loader_scroll').hide();
                        } else {
                            $('.loader_scroll').show();
                        }

                        if (typeof lazyload !== "undefined") {
                            lazyload.init();
                        }
                    }
                    $('#is_loading').val('0');
                },
                complete: function() {
                    var evt = new CustomEvent("generateNewBlocFilter_complete");
                    window.dispatchEvent(evt);
                }
            });
        }
    });
}

function changeFiltre(form, reset) {

    var value = getInputVal.bind(form),
        id = value('type_nav', 'tous');
    var type = value('type_tri'),
        idObj;
    var btn = this,
        idObjTmp;

    // If form must be reset
    if (reset) {
        if (filterformColors && filterformSize) {
            document.getElementById('wrapper_couleur_filtre').innerHTML = filterformColors;
            document.getElementById('wrapper_taille_filtre').innerHTML = filterformSize;
        }

        resetExtraFilters();
        form.reset();
        $('#filters_menu input[checked]').not('#wrapper_sscat_filtre input[checked]').removeAttr("checked"); //force suppression de tous les filtres checked (sauf ss-cat), ce qui arrive lorsqu'on revient d'une FP
        //document.getElementById("wrapper_couleur_filtre").innerHTML = filterMenuColors ;
        // On fait un reset des filtres pour récupérer toutes les tailles et toutes les couleurs
        //changeajaxfilter('reset', '');
        // Commented due to unwanted behavior, we need only form.reset() to reset filter form, we don't need to load all the sizes and colors from database so we keep the default.
    }

    $('legend.open', form).each(function() {

        toggleFiltersMenu.call(this);
    });

    // Check obj type
    if (form.sscat) {

        // Attention ce n'est pas des sscat mais des bien objets (views_mobile_new/wrapper_filters.php)
        idObjTmp = value('sscat');

        if (idObjTmp && idObjTmp.length > 0) {

            type = 'obj';
            $('#object_id').val(idObjTmp);
        } else {

            type = 'subcat';
        }
    }

    $('#type_tri').val(type);

    idObj = getCurrentIdRayon();

    // Display loader
    if (btn.nextElementSibling && btn.nextElementSibling.classList.contains('button')) {
        btn.nextElementSibling.style.display = 'block';
    }

    // Get new elements
    if (from_type == 'product') {
        generateNewBlocProd(type, idObj, 0, null, null, true, false, false);
    } else {
        generateNewBlocSearch.call(form, 0, 0, btn, 0, 0, type, idObj);
    }

    // Prevent default action
    return false;
}

// Close menu mobile by shade
$(document).on('click','#main_menu_btn .menu', function() {
    if ($(".top_search").hasClass("opened")) {
        $(".top_search").removeClass("opened");
        $("#shade").css("z-index", "32");
    }
});

/**
 * Check if 'element' is in viewport
 */
function isInViewport(element) {

    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

/**
 * Global .item_container slider initialization
 * isToggled => is true when from a toggle between 1 or 2 products per line (function defined in _app)
 */
function initItemSlider(isToggled) {
    if ($('.category .prodItemSlider, .search .prodItemSlider').length) {
        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php

        if (isToggled == true) { // Toggle view is triggerred
            setTimeout(function () {
                $('.category .prodItemSlider, .search .prodItemSlider').each(function () {
                    if ($(this).hasClass('initialized')) {
                        this.swiper.update();
                        this.swiper.slideTo(0);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else { // From page load and scroll
            $('.category .prodItemSlider').each(function () {
                if (isInViewport($(this)) && !$(this).hasClass('initialized') && $(this).find('.swiper-slide').length > 1) { // Prevent initialized sliders to go back to the first frame during scroll
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: '.itemSliderPagination',
                    });

                    $(this).addClass('initialized');
                }
            });

            $(".search .prodItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass('initialized') && $(this).find('.swiper-slide').length > 1) {
                    new Swiper($(this), {
                        slidesPerView: 1,
                        roundLengths: true,
                        observer: true,
                        observeParents: true,
                        preloadImages: false,
                        lazyLoading: true,
                        loadPrevNext: true,
                        loadOnTransitionStart: true,
                        pagination: '.itemSliderPagination',
                    });

                    $(this).addClass('initialized');
                }
            });
        }
    }
}

// Scroll the page to the first item, positioning it just below the header
function changeajaxfilterRayonExtra() {
    var headerHeight = $('.banner_wrapper').outerHeight();
    var offsetTop = $('.item.item_container:first').offset().top - headerHeight;
    $('html, body').animate({
        scrollTop: offsetTop
    }, 1000);
}
